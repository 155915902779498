// Import styles
@import "variables";
@import "../components/network-visualization/NetworkVisualization.scss";
@import "./modal.scss";
@import "./fonts.scss";

body {
  font-family: "Plaid", arial;
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  color: $dark-blue;
}

button {
  text-transform: none;
  -webkit-appearance: none;
  border-style: none;
  margin: 0;
  background-color: transparent;
  color: $dark-blue;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.title {
  font-size: 2.25em;
}

.wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 1200px;
  min-width: 900px;
  overflow: hidden;
}

.main-content {
  margin: 0 225px;
}

.list-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 222px;
  padding-top: 50px;
  text-align: left;
  margin-right: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.list-button {
  text-decoration: none;
  font-weight: 400;
  text-align: left;
  padding: 0.25em 0;
  width: 100%;

  &:hover {
    background-color: #39c6424b;
  }

  &:active {
    background-color: $theme-green;
  }

  &.selected {
    background-color: $theme-green !important;
  }
}

#resultsList {
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  max-height: 85%;
  position: relative;
  list-style: none;
  padding-inline-start: 0;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $transparent-dark-blue; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
  }
}

.loading-indicator {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: white;
  &.hide {
    opacity: 0;
    display: none;
    animation: none;
  }
}

.loading-indicator-content {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  transform: translate(-50%, -50%);
  animation: loadingAni 1s linear infinite;
}

@keyframes loadingAni {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.visual-container {
  position: relative;
  width: 100%;

  .visual {
    background-color: white;
    width: auto;
  }
}

.side-panel-container {
  z-index: 9;
  word-break: break-word;
  position: absolute;
  right: 0px;
  top: 70px;
  width: 300px;
  height: 100%;
  text-align: left;
  pointer-events: none;
  .side-panel {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: $padding;
    padding-left: $padding;
    width: 100%;
    background: $accent-color;
    color: white;
    transition: opacity 350ms;
    opacity: 1;
    &.hide {
      opacity: 0;
    }
    .close {
      text-align: right;
    }
    .meta-container {
      .meta-entry {
        padding-top: $padding;
        &:first-child {
          padding-top: 0;
        }
      }
      .label {
        font-size: 12px;
      }
      .value {
        font-weight: 700;
        font-size: 14px;
      }
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .description {
        font-size: 14px;
      }
      .footer {
        display: flex;
      }
      .url {
        pointer-events: all;
        flex-grow: 1;
        align-self: center;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

.linkedin-icon {
  path {
    fill: white;
  }
}

.close-icon {
  path {
    stroke: white;
    stroke-width: 2;
    stroke-linejoin: round;
  }
  &.blue {
    path {
      stroke: #003fb6;
    }
  }
}

.linkedin-icon,
.close-icon {
  pointer-events: all;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.input-container {
  margin: auto;
  width: 500px;
}

.dropdown-group {
  position: relative;
  z-index: 8;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 12px;

  .dropdown-container {
    position: relative;
    flex: 1;
    min-width: 150px;
    color: white;
    &:first-child {
      margin-right: 16px;
    }
    svg {
      color: white;
      position: absolute;
      top: 36%;
      bottom: 0;
      right: 0.7em;
    }

    .dropdown {
      width: 100%;
      display: inline-block;
      padding-left: 1em;
      display: inline-block;
      font-family: "Plaid", arial;
      font-size: 12px;
      color: white;
      background-color: $dark-blue;
      border-radius: 0px;
      border-color: $dark-blue;
      height: 32px;

      // Remove browser styles
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#count {
  font-weight: 500;
}

.hidden {
  display: none;
}

#modalButton {
  background-color: $theme-light-blue;
  text-decoration: none;
  color: white;
  padding: 0.6em 1em;
  font-weight: 400;
  font-family: "Plaid", arial;
  font-size: 12px;

  svg {
    margin-left: 0.5em;
  }
}

.disabled-button {
  background-color: #bdbdbd !important;
  pointer-events: none;
}
