@font-face {
  font-family: "Plaid";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/plaid-extra-small.otf") format("opentype");
}

@font-face {
  font-family: "Plaid";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/plaid-small.otf") format("opentype");
}

@font-face {
  font-family: "Plaid";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/plaid-medium.otf") format("opentype");
}

@font-face {
  font-family: "Plaid";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/plaid-large.otf") format("opentype");
}

@font-face {
  font-family: "Plaid";
  font-weight: bold;
  font-style: normal;
  src: url("./fonts/plaid-extra-large.otf") format("opentype");
}
