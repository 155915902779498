// Import styles
@import "variables";

.network-visualization {
  position: relative;
  display: inline-block;

  .svg-container {
    position: relative;
    display: inline-block;
    overflow: visible;

    .outer-circle {
      fill: #f4faff;
    }
    .inner-circle {
      fill: #edf7ff;
    }
    .text-path {
      fill: none;
    }
    .info-text {
      text-anchor: middle;
      font-family: "Plaid", arial;
      font-weight: 600;
      stroke: none;
      fill: $selected-blue;
      &.social_challenge {
        fill: $dark-blue;
      }
      &.expertise {
        fill: $dark-blue;
      }
    }
  }

  .html-labels {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;

    // Label on top
    .hover-label-container {
      position: absolute;
      text-align: left;
      z-index: 9;

      .hover-label {
        padding: $padding * 0.5;
        transform: translate(0, -50%);
        margin-left: $margin * 0.5;
        background-color: $accent-color;
        border-radius: $border-radius;
        color: white;

        max-width: 200px;
        white-space: normal;
        width: max-content;
        .name {
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          word-break: break-word;
        }
        .role {
          font-size: 14px;
          font-weight: 300;
        }
        // https://cssarrowplease.com/
        &:after,
        &:before {
          right: 100%;
          top: 50%;
          border: solid transparent;
          border-right-color: $accent-color;

          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-width: 3px;
          margin-top: -3px;
        }
        &:before {
          border-width: 6px;
          margin-top: -6px;
        }
      }

      .left-label {
        transform: translate(-100%, -50%) translateX(-$margin * 0.5);
        margin-left: 0;

        &:after,
        &:before {
          left: 100%;
          right: unset;
          border-right-color: transparent;
          border-left-color: $accent-color;
        }
      }

      &.hide {
        display: none;
      }
    }

    .label-container {
      position: absolute;

      &.social_challenge {
        color: $dark-blue;
        .label {
          transform: translate(-50%, -100%);
          padding-bottom: 25px;
        }
      }
      &.expertise {
        color: $dark-blue;
        .label {
          transform: translate(-50%, 0%);
          padding-top: 25px;
        }
      }
      .label {
        position: relative;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        width: 125px;
      }
    }
  }

  .link-group {
    .link {
      stroke: $quaternary-color;
      stroke-width: 1;
      &.isFilteredLink {
        stroke-width: 1.5;
      }
      &.isHighlighted {
        stroke-width: 3;
        stroke: $selected-blue;
      }
    }
  }
  .node-group {
    .node {
      fill: $secondary-color;
      stroke: white;
      stroke-width: 1;

      &.expertise {
        fill: $tertiary-color;
      }

      &.social_challenge {
        fill: $primary-color;
      }

      .backdrop-circle {
        fill: white;
      }

      &.expertise,
      &.social_challenge {
        stroke-width: 4;
        // Highlight when selected
        &.isFilteredNode {
          stroke: $selected-blue;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &.company {
        &.isSelected {
          fill: $accent-color;
          stroke-width: 4;
          stroke: $selected-blue;
        }
        &.isInteractive {
          &.hovered {
            fill: $accent-color;
            cursor: pointer;
            stroke-width: 4;
            stroke: $selected-blue;
          }
        }
        // Fix for FF
        &.isInteractive {
          cursor: pointer;
        }
      }
    }
  }
}
