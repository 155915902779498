@import "variables";

.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $transparent-dark-blue;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 2em 4em 3em 4em;
    background-color: white;

    #close-button {
      position: absolute;
      bottom: calc(100% + 10px);
      right: 0;

      svg {
        color: $dark-blue;
        stroke: $dark-blue;
      }
    }

    h2 {
      text-align: left;
    }

    table {
      table-layout: fixed;
      position: relative;
      width: 100%;
      text-align: left;
      margin-bottom: 1.5em;

      tbody {
        display: block;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
      }

      tbody::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
        height: 5px;
      }

      tbody::-webkit-scrollbar-thumb {
        background-color: $transparent-dark-blue; /* color of the scroll thumb */
        border-radius: 50px; /* roundness of the scroll thumb */
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tr th:nth-child(1),
      tr td:nth-child(1) {
        width: 28%;
      }

      tr th:nth-child(2),
      tr td:nth-child(2) {
        width: 28%;
      }

      tr th:nth-child(3),
      tr td:nth-child(3) {
        width: 28%;
      }

      tr th:nth-child(4),
      tr td:nth-child(4) {
        width: 16%;
      }

      // tr th:nth-child(5),
      // tr td:nth-child(5) {
      //   width: 10%;
      // }

      td {
        padding-top: 0.2em;
        padding-bottom: 0.6em;
      }

      tr {
        position: relative;
        width: 100%;

        &:first-child:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $dark-blue;
        }

        &:not(:last-child):not(:first-child):after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $transparent-dark-blue;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: $dark-blue;
      }

      .headers {
        position: relative;

        th {
          font-weight: 500;
          padding-bottom: 0.8em;
        }
      }
    }
  }

  .modal-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0px 0px;
    border-color: transparent $theme-green transparent transparent;
    transform: rotate(90deg);
  }
}

#download-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-right: 2em;

    .multi-input-row {
      display: flex;
      justify-content: stretch;

      input:not(:last-child) {
        margin-right: 1em;
      }
    }

    input[type="text"],
    input[type="email"] {
      flex: 1;
      border-radius: 0;
      border: solid 1px $dark-blue;
      padding: 0.5em 1em;
      margin-bottom: 0.75em;
    }
  }

  button {
    margin-left: 1rem;
    background-color: $dark-blue;
    color: white;
    text-decoration: none;
    padding: 0.5em 2.5em;
  }
}

#list-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1em;

  span {
    margin-right: 2em;
    font-size: 14px;
    font-weight: 500;
  }
}
